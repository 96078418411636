 @font-face { 
    font-family: "Sting,Helvetica,sans-serif";
    src: local("Sting,Helvetica,sans-serif"), 
    url("./fonts/UniversLTStd-Cn.woff2") format("woff2"); 
  } 

  @font-face {
    font-family: "sting-bold";
    src: local("sting-bold"),
     url("./fonts/palace-main.woff2") format("woff2");
    }

    @font-face {
      font-family: "sting-light";
      src: local("sting-light"),
      url("./fonts/UniversLTStd-Cn.woff2") format("woff2");
      }

      
      .subscription-plan-home {
        display: none;
      }
      .prizes-section_cupholder {
        left: 0;
        right: 0;
        top: 44%;
      }
  .font-face-sh {
    font-family: "Sting,Helvetica,sans-serif";
  }
  .font-face-sh-bold {
    font-family: "sting-bold";
  }
  .font-face-sh-light {
    font-family: "sting-light";
  }

  .play-icon, .fund-icon, .win-icon {
    font-size: 40px;
  }
   .play-icon.position-relative::after {
    content: "";
    background-image: url('./images/play-icon.png');
    width: 28px;
    height: 28px;
    background-size: contain;
    display: block;
position: absolute;
right: -30px;
top: 0;
  }

  .fund-icon.position-relative::after {
    content: "";
    background-image: url('./images/fund-icon.png');
    width: 28px;
    height: 28px;
    background-size: contain;
    display: block;
position: absolute;
right: -30px;
top: 0;
  }

  .win-icon.position-relative::after {
    content: "";
    background-image: url('./images/win-icon.png');
    width: 28px;
    height: 28px;
    background-size: contain;
    display: block;
position: absolute;
right: -30px;
top: 0;
  }

   .branding-1 {
    color: #0055a5;
   }
   .notfound-button:hover {
    background-color: #ff1c25 !important;
  }
   .branding-1-hover:hover {
    color: #0055a5 !important;
   }
   .bg-branding-green {
    background-color: #05b304;
   }
   .bg-branding-green:hover {
    background-color: #05b304 !important;
   }
   .bg-branding-btn {
    background-color: #0055a5;
   }
   .bg-branding-btn:hover {
    background-color: #0055a5 !important;
   }

   .branding-1-light-bg {
    background-color: #ff1c25;
   }
   .bg-branding-1 {
    background-color: #0055a5;
   }
   .bg-branding-1-hover:hover {
    background-color: #0055a5 !important;
   }
   .bg-branding-2 {
    background-color: #db2b27;
   }
   .custom-text{
    color:#db2b27;
    text-shadow:-4px 4px 0 #fff,-4px 3px 0 #fff,-2px 4px 0 #fff,-3px 3px 0 #fff,-3px 2px 0 #fff,-1px 3px 0 #fff,-2px 2px 0 #fff,-2px 1px 0 #fff,0 2px 0 #fff,-1px 1px 0 #fff,-1px 0 0 #fff,1px 1px 0 #fff,1px -1px 0 #fff,-1px -1px 0 #fff
   }
   .border-branding-1 {
    border-color: #0055a5 !important;
   }
   .branding-1 {
    color: rgb(0, 85, 165);
  }
   .branding-2 {
    color: #db2b27;
   }
   
   .branding-3 {
    color:  #302d51;
   }
   .bg-green-clr {
    background-color: #05b304;
   }
   .bg-green-clr:hover {
    background-color: #05b304;
   }
   .black-clr {
    color: #000000;
   }
   .bg-position {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
   }
   .rounded-3 {
    border-radius: 12px !important;
  }
  .z-index-9 {
    z-index: 99999;
  }
  .modal-header .btn-close {
    position: absolute;
    right: 15px;
  }
  .fade.modal.show, .modal-backdrop.show {
    z-index: 99999;
  }
  .hero-background-gradient{
   background: linear-gradient(135deg,#0055a5,#0055a5 52%,#db2b27 0,#db2b27);
  }
   .herosec-img {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: top !important;
    --gradientSize: 99vw 99%;
    background-image: radial-gradient(100% 100% at top, #0000 100%,#fff 100% 100%),url("./images/HeroBg.png");
   }

   .container {
    max-width: 1200px !important;
    padding: 0px 30px;
   }
   .container-2 {
    max-width: 1030px !important;
    margin: 0 auto;
   }
   .checkbox-item input[type="date"], .dob-field input[type="date"] {
    appearance: none;
    -moz-appearance: none;
  }
  .dob-field {
    position: relative;
  }
  .dob-field label {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto !important;
    padding: 16px 30px;
    background-color: #F9F9FB;
    border-radius: 50px;
    cursor: pointer;
  }
  .dob-field input:focus + label {
    opacity: 0 !important;
    display: none !important;
  }
  .dob-field input {
    cursor: pointer;
  }
   .prizes-section {
    overflow: hidden;
   }
   .prizes-section::before {
    content: "";
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
    background-repeat: no-repeat;
    --gradientSize: 117vw 98%;
    background-image: radial-gradient(ellipse var(--gradientSize) at top,#0055a5 0,#0055a5 99.9%,#ffffff 100%);
    width: 100vw;
    height: 72%;
    top: -17px;
    left: 50%;
    background-position: top;
    background-size: 100% auto;
    --gradientSize: 77vw 80%;
    padding-top: 80px;
   }

   .slick-dots {
    bottom: -155px;
    width: auto;
    margin: auto;
    list-style: none;
    text-align: center;
    background-color: #fff;
    display: flex !important;
    box-shadow: 0 4px 28px rgba(var(--color-black-rgb),.16);
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 10px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 0 14px;
    z-index: 99;
    height: 24px;
  }
  .slick-dots li {
    margin: 0;
    width: 12px;
  }
    .slick-dots li button {
      width: 12px;
      padding: 0;
    }
    .slick-dots li button::before {
      width: 12px;
      color: gray;
      opacity: 1;
      height: 12px;
border-radius: 50%;
    }
    .slick-dots li.slick-active button::before {
      opacity: 1;
      color: #0055a5;
      font-size: 9px;
    }
    .light-border {
      border:1px solid hsla(0,0%,100%,.25);
    }
    .footer-section::after {
      position: absolute;
      z-index: -1;
      bottom: -11px;
      display: block;
      width: 100%;
      height: 132px;
      content: "";
      background-image: url('./images/wave.svg');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: contain;
      height: 332px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }

    .input {
      background: #F9F9FB;
      border-radius: 50px;
      height: 56px;
      border: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 30px;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #4F6076;
      outline: none;
      margin-top: 0px;
    }
    .terms {
      font-size: 13px;
      text-align: center;
      line-height: 1.6;
      color: #43465E;
    }

    /* Terms and condition */

    .terms-conditions-text {
      padding: 50px 24px;
      max-width: 1110px;
      margin: auto;
      font-size: 16px;
      font-family: "Sting,Helvetica,sans-serif";
    }

    .terms-conditions-text h1 {
      font-size: 32px;
      margin: 0px;
      padding: 0px;
      font-family: "Sting,Helvetica,sans-serif";
    }
    .terms-conditions-text h2{
      font-size: 24px;
      margin: 0px;
      padding: 0px;
      font-family: "Sting,Helvetica,sans-serif";
    } 

    /* Demo Actyive color*/
    .ticket-title-sec:before {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: #ffffff;
      border: 1px solid #dfdfdf;
      position: absolute;
      top: 10px;
      left: 9px;
      z-index: 1;
    }
    /* .ticket-title-sec.active:before {
      border-color: #000000;
    }
    .ticket-title-sec.active:after {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #0055a5;
      position: absolute;
      top: 15px;
      left: 14px;
      z-index: 1;
    } */
    .step-subscription-plan-selector ul li.active .ticket-title-sec::before {
      border-color: #000000;
    }
    .step-subscription-plan-selector ul li.active .ticket-title-sec:after {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: #0055a5;
      position: absolute;
      top: 15px;
      left: 14px;
      z-index: 1;
    }
    .step-subscription-plan-selector ul li.active .ticket-price {
      background-color: #000000 !important;
      color: #ffffff;
    }
    
    
    /* Side Bar */
.sidebar {transition: 0.5s ease-in-out; opacity: 0;}.sidebar.open{ width: 100%;opacity: 1;  z-index:  1030; }

.w-0 {
  width: 0;
}

.MenuButton_root {
  position: relative;
  top: 0px;
  display: block;
  width: 40px;
  height: 23px;
  -webkit-transform: rotate(-5deg) scale(.93);
  transform: rotate(-5deg) scale(.93);
  right: -1px;
}
.MenuButton_firstLine {
  position: absolute;
  width: 43px;
  height: 5px;
  background: #ffffff;
  transition: .25s;
  right: 0;
}
.MenuButton_secondLine {
  position: absolute;
  top: 9px;
  right: 0;
  width: 33px;
  height: 5px;
  background: #ffffff;
  -webkit-transform: rotate(1deg);
  transform: rotate(1deg);
  transition: .25s;
}
.MenuButton_thirdLine {
  position: absolute;
  top: 18px;
  right: 0;
  width: 33px;
  height: 5px;
  background: #ffffff;
  -webkit-transform: rotate(-1deg);
  transform: rotate(-1deg);
  transition: .25s;
  opacity: 1;
}
.MenuButton_rootActive {
  position: relative;
  -webkit-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  z-index: 99999
}
.MenuButton_firstLineActive {
  top: 0px;
  right: 0;
  height: 4px;
  width: 30px;
  background-color: #ff1c25;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
}
.MenuButton_secondLineActive {
  right: 0;
  width: 30px;
  height: 4px;
  background-color: #ff1c25;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.MenuButton_thirdLineActive {
  opacity: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*Subscription Plan*/
.fs-22 {
  font-size: 22px;
}
.input {
  background: #f2f2f2;
  border-radius: 50px;
  height: 56px;
  border: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 30px;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #4F6076;
  outline: none;
  margin-top: 0px;
}
.input::placeholder {
  opacity: 0.7;
}
.terms {
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  line-height: 1.6;
  color: #43465E;
}
.terms-link {
  font-weight: 800;
  text-decoration: none;
  color: #43465E;
}
.ticket-title-sec:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  position: absolute;
  top: 10px;
  left: 9px;
  z-index: 1;
}
/* .ticket-title-sec.active:before {
  border-color: #000000;
}
.ticket-title-sec.active:after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #0055a5;
  position: absolute;
  top: 15px;
  left: 14px;
  z-index: 1;
} */



.step-subscription-plan-selector ul li.active .ticket-title-sec::before {
  border-color: #0055a5;
}
.step-subscription-plan-selector ul li.active .ticket-title-sec:after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #0055a5;
  position: absolute;
  top: 15px;
  left: 14px;
  z-index: 1;
}
.step-subscription-plan-selector ul li.active .ticket-price {
  background-color: #c61d23 !important;
  color: #ffffff;
}
.draw-entries-icon:before {
  content: "";
  background-color: #e8e6f7;
  top: 9px;
  left: 9px;
  width: 34px;
  height: 34px;
  border-radius: 17px;
  position: absolute;
}
.draw-entries-icon:after {
  -webkit-mask: url('./images/pound.svg');
  mask: url('./images/pound.svg');
  background-color: #6259d2;
    content: "";
    min-width: 22px;
    max-width: 22px;
    height: 22px;
    position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
margin: auto;
}

/* Terms and condition */

    .terms-conditions-text {
      padding: 50px 24px;
      max-width: 1110px;
      margin: auto;
      font-size: 16px;
      font-family: "Sting,Helvetica,sans-serif";
    }

    .terms-conditions-text h1 {
      font-size: 32px;
      margin: 0px;
      padding: 0px;
      font-family: "Sting,Helvetica,sans-serif";
    }
    .terms-conditions-text h2{
      font-size: 24px;
      margin: 0px;
      padding: 0px;
      font-family: "Sting,Helvetica,sans-serif";
    } 
    

.ul-tick li:before {
  content: "";
  vertical-align: bottom;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  display: inline-block;
  -webkit-mask: url('./images/check.svg');
  mask: url('./images/check.svg');
  background-color: #05b304;
  margin-right: 6px;
}
.input-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.input-radio input:checked~.input-radio-label {
  background-color: #0055a5;
  color: #fff;
}
.input-radio .input-radio-label {
  background: #f8f8f8;
  height: 48px;
  display: grid;
  -webkit-align-content: center;
  align-content: center;
  padding: 0 24px;
  border-radius: 50px;
  color: rgba(var(--color-black-rgb),.5);
  font-size: 14px;
}
.summary-info-impact .summary-info-impact_title::before {
  content: "";
  background-image: url('./images/tick.svg');
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.input-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.input-checkbox_check {
  height: 24px;
  min-width: 24px;
  max-width: 24px;
  margin-right: 8px;
  background-color: rgba(10,213,96,.05);
  border: 1px solid rgba(10,213,96,.7);
  border-radius: 16px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  cursor: pointer;
}
.input-checkbox input:checked~.input-checkbox_check:after {
  width: 22px;
  height: 22px;
}
.input-checkbox input:checked~.input-checkbox_check:after {
  content: "";
  width: 18px;
  height: 18px;
  background-image: url('./images/checkbox-tick.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: rgba(10,213,96);
  border-radius: 20px;
}
.input-checkbox-label {
  color: #302d51;
  font-size: 15px;
  line-height: 25px;
  cursor: pointer;
}
.bg-grey {
  background-color: #f6f6f6;
}
.fs-14 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}
.navigation-item.active .navigation-item-link {
  box-shadow: 0 0 11px rgba(7,20,136,.77);
}
.home .dropdown #dropdown-menu-align-end {
  background-color: transparent;
  border: none;
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 1.1rem;
}
.dropdown #dropdown-menu-align-end, .open.sidebar .dropdown #dropdown-menu-align-end {
  background-color: transparent;
  border: none;
  color: #fff;
  font-weight: 700;
  font-size: 1.1rem;
}
.dashboard-header .dropdown #dropdown-menu-align-end, .open.sidebar .dropdown #dropdown-menu-align-end {
  background-color: transparent;
  border: none;
  color: #0055a5;
  font-weight: 700;
  font-size: 1.1rem;
}
.dropdown #dropdown-menu-align-end::after {
  display: none;
}
.dropdown-menu.show.dropdown-menu-end {
  box-shadow: 0 0 20px -5px rgba(0,0,0,.3);
  border-radius: 0px;
  border: none;
  background-color: #fff;
  transform: translate(0px, 59px) !important;
  transition: opacity .5s;
}
.dropdown-menu.show.dropdown-menu-end .dropdown-item {
  font-size: 1.1rem;
  color: #585858;
  padding: 6px 20px;
line-height: 1.5rem;
}
.dropdown-menu.show.dropdown-menu-end .dropdown-item:hover {
  opacity: 0.5;
}
.dropdown-menu.show.dropdown-menu-end:after {
  border-bottom: 8px solid #ffffff;
  top: -6px;
}
.dropdown-menu.show.dropdown-menu-end:after, .dropdown-menu.show.dropdown-menu-end:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  right: 30px;
}
.free-entry-modal_title:after {
  content: "";
  background-image: url('./images/title-bg.svg');
  background-size: cover;
  position: absolute;
  width: 414px;
  height: 414px;
  left: 0;
  right: 0;
  top: -5px;
  margin: auto;
}
.step-subscription-plan-selector ul {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.Toastify {
  z-index: 9999999;
  position: relative;
}
.PhoneInputInput, .PhoneInputInput:focus-visible{
  border: none !important;
  outline: none !important;
  background-color: #f2f2f2;
}

.prizes-section_container.mx-auto.bg-branding-1.pb-3 {
  width: 100% !important;
}
@media only screen and (max-width: 991px) {
  
.desktop-menu {
  display: none !important;
}
.mobile-menu {
  display: flex !important;
}
.site-header {
  background-color: #0055a5;
  z-index: 99999;
position: relative;
}
}

@media only screen and (max-width: 1200px) {
  .herosec-img {
    background-image: radial-gradient(100% 110% at top, #0000 100%,#fff 0% 0%),url("./images/HeroBg.png");
  }
}

@media only screen and (max-width: 991px) {
  .desktop-menu {
    display: none !important;
  }
  .mobile-menu {
    display: flex !important;
  }
  .site-header {
    background-color: #0055a5;
    z-index: 99999;
  position: relative;
  }
  .site-header.dashboard-header {
    background-color: #ffffff;
  }
  .account-navigation .navigation-item.settings {
    display: none !important;
  }
  .dashboard-menu {
    display: none !important;
  }
  .dashboard-header .MenuButton_firstLine, .dashboard-header .MenuButton_secondLine, .dashboard-header .MenuButton_thirdLine {
    background: #0055a5;
  }
  .for-mobile {
    display: block !important;
  }
  }

@media only screen and (max-width: 767px) {
  .palace-thankyou .footer-section {
    padding-bottom: 0px;
  }
  .box-logo.bg-dark.rounded-5.py-5.px-3.text-center.shadow-sm.position-relative {
    width: 50%;
    margin: 0 auto;
}
.logo-thank-you .share-icons.w-50 {
  width: 30% !important;
}
.col-md-8.align-middle.pb-md-0.pb-4.font-face-gb.thank-you-title {
  text-align: center;
  margin-bottom: 30px;
}
.container-fluid.thank-home.home-left-side.mb-md-0 {
  padding-top: 500px !important;
  padding-bottom: 40px !important;
}
.thankyou-header {
  background-size: unset !important;
}


  .wt-100 {
    width: 100% !important;
  }
  .matchcard-logosblock {
    padding: 30px 15px 0px 15px !important;
    border-bottom-left-radius: 70px !important;
  }
  .previous-winners_section .container {
    padding: 0;
  }
  .match-card {
    margin: 0 !important;
  }
  .matchcard-header img {
    width: 140px !important;
  }
  .desktop-btn {
    display: none;
  }
  .mobile-bottom {
    display: block !important;
  }
  .mobile-bottom button {
    width: 100% !important;
  }
  .hero-section {
    padding-bottom: 130px !important;
  }
  .stories-section-title {
    margin-top: 0px !important;
  }
  .stories-container {
    width: 100% !important;
  }
  .stories-container {
    width: 100% !important;
    border: none;
    padding: 0 !important;
    text-align: center;
  }
  .story-block-stats .d-flex.align-items-center {
    justify-content: center;
  }
  .story-block-testimonial h4 {
    margin-top: 30px;
  }
  .story-photo img {
    width: 108px !important;
    margin: 0 auto;
  }
  .curve-dot {
    bottom: -39px !important;
  }
  .story-block-inner {
    padding-bottom: 95px !important;
  }
  .slick-dots {
    bottom: -60px;
  }
  .prizes-section_container {
    width: 89% !important;
  }
  .prizes-section_blockcontainer_sec {
    margin-left: -24px;
    margin-right: -24px;
  }
  .prizes-section-prizes_container {
    margin-left: -24px !important;
    margin-right: -24px !important;
    margin-top: 20px !important;
  }
  .prizes-section-prizes_container h3 {
    font-size: 20px !important;
  }
  .prizes-section-prizes_container .col-md-6 {
    width: 50%;
  }
  .how-it-works_section .col-md-4 P {
    margin-top: 30px !important;
    margin-bottom: 40px;
  }
  .container-2 {
    max-width: 89% !important;
    margin: 0 auto;
  }
  .charity_section p.m-auto {
    margin: 0 !important;
    width: 100% !important;
    margin-top: 20px !important;
  }
  .charity_section .col-sm-12.col-md-12.col-lg-6.pe-5 {
    padding-right: 12px !important;
  }
  .charity_section button {
    width: 100% !important;
    margin-top: 30px !important;
    margin-bottom: 50px !important;
  }
  .bg-branding-green {
    width: 100% !important;
  }
  .container {
    padding: 0px 20px;
  }
  .prizes-section::before {
    width: 100vw;
    height: 97%;
  }
  .subscription-plan-container {
    max-width: 375px !important;
  }
  .step-subscription-plan-selector ul {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
  .subscription-plan-price_currency {
    margin-top: 5px !important;
  }
  .ticket-title-sec img {
    height: 55px !important;
  }
  .ticket-title-sec h4 {
    font-size: 16px !important;
  }
  .ticket-title-sec {
    padding-bottom: 20px !important;
  }
  .StepSubscriptionPlan_topBackground {
    height: 300px !important;
    border-radius: 0px 0px 78px 78px;
    background-color: #0055a5;
  }
  .StepSubscriptionPlan_topBackground path {
    display: none;
  }
  .subscription-plan-container .step-user-details-info {
    padding-left: 0px;
    margin-top: 0 !important;
  }
  .subscription-plan-container .step-user-details {
    padding-right: 0 !important;
    padding-left: 0;
  }
  }
  .step-subscription-plan-selector.home-data ul {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr !important;
    grid-gap: 10px !important;
  }
@media only screen and (max-width: 479px) {
  .col-md-6.pe-2.mb-4.winners-data {
    width: 90% !important;
    padding: 7px;
  }
  .prizes-section_prizes_bg h3 {
    font-size: 14px !important;
    padding: 5px !important;
  }
  .notfound a {
    width: 40% !important;
    margin: 20px 10px !important;
    font-size: 16px !important;
  }
  .notfound h1 {
    font-size: 28px !important;
  }
  .notfound h5 {
    font-size: 18px !important;
  }
  .footer-section::after {
    background-size: cover;
    height: 100%;
  }
  .notfound img {
    width: 80% !important;
  }


  .box-logo.bg-dark.rounded-5.py-5.px-3.text-center.shadow-sm.position-relative {
    width: 100%;
    margin: 0 auto;
  }
  .logo-thank-you .share-icons.w-50 {
    width: 70% !important;
  }
  .container-fluid.home-left-side.mb-md-0.position-relative.thankyou-header {
    padding-top: 20px !important;
  }
  .container-fluid.thank-home.home-left-side.mb-md-0 {
    padding-top: 450px !important;
    padding-bottom: 40px !important;
  }
  .btn.thankyou-page-button.text-uppercase.bg-branding-1.border-0.rounded-pill.py-3.px-5.lh-1.text-light {
    padding: 20px !important;
  }
  .footer-section::after {
    background-size: cover;
  }


  #hubspot-messages-iframe-container.widget-align-right {
    display: none !important;
  }
  .steps-subscription-plan {
    padding-left: 20px;
    padding-right: 20px;
  }
  .home-footer {
    padding-bottom: 300px;
  }
  .subscription-plan-home {
    display: block;
  }
  .free-entry-modal_title_block {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px !important;
  }
  .free-entry-modal_description_block {
    margin-top: 0px !important;
    max-width: 320px !important;
  }
  .free-entry-modal_title_block img.cross {
    right: 10px !important;
    top: -30px !important;
    width: 40px !important;
  }
  .personal-details .subscription-plan-container , .payment-details .subscription-plan-container {
    max-width: 330px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .account-impact-section {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 991px) {
.desktop-menu {
  display: none !important;
}
.mobile-menu {
  display: flex !important;
}
.site-header {
  background-color: #0055a5;
  z-index: 99999;
position: relative;
}
.site-header.dashboard-header {
  background-color: #ffffff;
}
.account-navigation .navigation-item.settings {
  display: none !important;
}
.dashboard-menu {
  display: none !important;
}
.dashboard-header .MenuButton_firstLine, .dashboard-header .MenuButton_secondLine, .dashboard-header .MenuButton_thirdLine {
  background: #0055a5;
}
.for-mobile {
  display: block !important;
}
}


@media only screen and (max-width: 767px) {
  .notfound {
    width: 100% !important;
  }
  .notfound h1 {
    font-size: 40px !important;
  }
  .notfound-page {
    padding: 30px 0px !important;
  }


  .wt-100 {
    width: 100% !important;
  }
  .matchcard-logosblock {
    padding: 30px 15px 0px 15px !important;
  }
  .match-card {
    margin: 0 !important;
  }
  .matchcard-header img {
    width: 140px !important;
  }
  .desktop-btn {
    display: none;
  }
  .mobile-bottom {
    display: block !important;
  }
  .mobile-bottom button {
    width: 100% !important;
  }
  .hero-section {
    padding-bottom: 130px !important;
  }
  .stories-section-title {
    margin-top: 0px !important;
  }
  .stories-container {
    width: 100% !important;
  }
  .stories-container {
    width: 100% !important;
    border: none;
    padding: 0 !important;
    text-align: center;
  }
  .story-block-stats .d-flex.align-items-center {
    justify-content: center;
  }
  .story-block-testimonial h4 {
    margin-top: 30px;
  }
  .story-photo img {
    width: 108px !important;
    margin: 0 auto;
  }
  .curve-dot {
    bottom: -39px !important;
  }
  .story-block-inner {
    padding-bottom: 95px !important;
  }
  .slick-dots {
    bottom: -60px;
  }
  .prizes-section_container {
    width: 89% !important;
  }
  .prizes-section_blockcontainer_sec {
    margin-left: -24px;
    margin-right: -24px;
  }
  .prizes-section-prizes_container {
    margin-left: -24px !important;
    margin-right: -24px !important;
    margin-top: 20px !important;
  }
  .prizes-section-prizes_container h3 {
    font-size: 20px !important;
  }
  .prizes-section-prizes_container .col-md-6 {
    width: 50%;
  }
  .how-it-works_section .col-md-4 P {
    margin-top: 30px !important;
    margin-bottom: 40px;
  }
  .container-2 {
    max-width: 89% !important;
    margin: 0 auto;
  }
  .charity_section p.m-auto {
    margin: 0 !important;
    width: 100% !important;
    margin-top: 20px !important;
  }
  .charity_section .col-sm-12.col-md-12.col-lg-6.pe-5 {
    padding-right: 12px !important;
  }
  .charity_section button {
    width: 100% !important;
    margin-top: 30px !important;
    margin-bottom: 50px !important;
  }
  .bg-branding-green {
    width: 100% !important;
  }
  .container {
    padding: 0px 20px;
  }
  .prizes-section::before {
    width: 100vw;
    height: 97%;
  }
  .subscription-plan-container {
    max-width: 375px !important;
  }
  .step-subscription-plan-selector ul {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }
  .subscription-plan-price_currency {
    margin-top: 5px !important;
  }
  .ticket-title-sec img {
    height: 55px !important;
  }
  .ticket-title-sec h4 {
    font-size: 16px !important;
  }
  .ticket-title-sec {
    padding-bottom: 20px !important;
  }
  .StepSubscriptionPlan_topBackground {
    height: 300px !important;
    border-radius: 0px 0px 78px 78px;
    background-color: #0055a5;
  }
  .StepSubscriptionPlan_topBackground path {
    display: none;
  }
  .subscription-plan-container .step-user-details-info {
    padding-left: 0px;
    margin-top: 0 !important;
  }
  .subscription-plan-container .step-user-details {
    padding-right: 0 !important;
    padding-left: 0;
  }
  .claim-methods button.btn {
    padding: 0.8rem 0.8rem !important;
  }
  /*Card Payment Method*/
  .payment-card-modal-header.modal-header {
    right: 0;
    /* padding-top: 0; */
  }
  .payment-card-modal-header.modal-header .modal-title div {
    padding: 0 !important;
  }
  .payment-card-modal-body.modal-body {
    padding-left: 12px;
    padding-right: 12px;
  }
  .payment-card-modal-body.modal-body .row.mt-3 .col-6 .row.pe-2 {
    padding-right: 4px !important;
  }
  .payment-card-modal-body.modal-body .row.mt-3 .col-6 .row.ps-2 {
    padding-left: 4px !important;
  }
  .card-brand-logo {
    width: 50px !important;
    top: -5px !important;
  }
  .card-brand-logo-type {
    width: 40px !important;
  }
  .payment-card-number {
    font-size: 18px !important;
  }
  .payment-card-holdername {
    font-size: 18px !important;
  }
  .make-default-btn {
    width: 50px !important;
  }
  }

@media only screen and (max-width: 479px) {
  .steps-subscription-plan {
    padding-left: 20px;
    padding-right: 20px;
  }
  .free-entry-modal_title_block {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 0px !important;
  }
  .free-entry-modal_description_block {
    margin-top: 0px !important;
    max-width: 320px !important;
  }
  .free-entry-modal_title_block img.cross {
    right: 10px !important;
    top: -30px !important;
    width: 40px !important;
  }
  .personal-details .subscription-plan-container , .payment-details .subscription-plan-container {
    max-width: 330px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
  .account-impact-section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .payment-card-modal-footer.modal-footer .card-foot-inner {
    margin: 0;
    padding-top: 5px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .payment-card-modal-footer.modal-footer .card-foot-inner .btn {
    width: 50%;
  }
  .payment-card-modal-footer.modal-footer .card-foot-inner .row div {
    padding: 0;
  }
}

@media only screen and (max-width: 350px) {
  .account-impact-top_bg .position-absolute.start-0.end-0 {
    top: 0px !important;
  }
  .account-impact_logo.mt-3.text-center {
    margin: 0px !important;
  }
  .navigation-list a {
    font-size: 12px;
  }
}